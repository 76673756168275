.p-person{
    &__detail{
        background: url('../../../images/bg-detail-person.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include media-breakpoint-up('md'){
            max-height: 621px;
        }
    }
}
