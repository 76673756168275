// Styles

// Main styles

@import "./app-oim.scss";

// Hyper Icons
@import "./icons.scss";

html {
  box-sizing: border-box;
}

*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

span.text-error.required,
span.error.required {
  margin: 0px 5px;
  font-size: 17px;
  vertical-align: middle;
}

.text-hint {
  color: #4066b8;
  font-style: italic;
  font-size: 0.8rem;
}

.swal2-styled.swal2-confirm {
  background-color: #5b92e5 !important;
  &:focus {
    box-shadow: 0 0 0 3px !important;
  }
}

.consentment-tooltip > .tooltip-inner {
  background-color: #e7f4f3;
  color: #262626;
  border: 1px solid #70c3bd;
  top: 0;
}

#tooltip-top > .tooltip-arrow {
  border-top: none;
}

button.btn-group-selectable {
  background: none;
  color: #5b92e5;
  border: 1px solid #5b92e5;

  .active {
    background-color: #5b92e5;
    color: #fff;
  }
}

.text-secondary {
  color: #202020 !important;
  font-weight: 400;
}

.hidden-element {
  visibility: hidden;
}

.table-mobile {
  width: 100%;
}

.button-asignation {
  text-align: end;
}

@media screen and (max-width: 700px) {
  .table-mobile {
    width: 600px;
    overflow-x: scroll;
  }

  .button-asignation {
    text-align: start;
    margin: 10px 0;
  }
}

#dropdown-services::after {
  display: none !important;
}
