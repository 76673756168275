@font-face {
    font-family: "Open Sans";
    src: url("../../../fonts/OpenSans-Light.eot");
    src: local("Open Sans Light"), local("OpenSans-Light"),
        url("../../../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/OpenSans-Light.woff") format("woff"),
        url("../../../fonts/OpenSans-Light.woff2") format("woff2"),
        url("../../../fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../../../fonts/OpenSans-Regular.eot");
    src: local("Open Sans Regular"), local("OpenSans-Regular"),
        url("../../../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/OpenSans-Regular.woff") format("woff"),
        url("../../../fonts/OpenSans-Regular.woff2") format("woff2"),
        url("../../../fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../../../fonts/OpenSans-Medium.eot");
    src: local("Open Sans Medium"), local("OpenSans-Medium"),
        url("../../../fonts/OpenSans-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/OpenSans-Medium.woff") format("woff"),
        url("../../../fonts/OpenSans-Medium.woff2") format("woff2"),
        url("../../../fonts/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../../../fonts/OpenSans-SemiBold.eot");
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
        url("../../../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/OpenSans-SemiBold.woff") format("woff"),
        url("../../../fonts/OpenSans-SemiBold.woff2") format("woff2"),
        url("../../../fonts/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../../../fonts/OpenSans-Bold.eot");
    src: local("Nunito Bold"), local("OpenSans-Bold"),
        url("../../../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/OpenSans-Bold.woff") format("woff"),
        url("../../../fonts/OpenSans-Bold.woff2") format("woff2"),
        url("../../../fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../../../fonts/OpenSans-ExtraBold.eot");
    src: local("Open Sans Extra Bold"), local("OpenSans-ExtraBold"),
        url("../../../fonts/OpenSans-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../../../fonts/OpenSans-ExtraBold.woff") format("woff"),
        url("../../../fonts/OpenSans-ExtraBold.woff2") format("woff2"),
        url("../../../fonts/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
