//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.bg-section {
  background-image: url("../../../images/bg-section.svg");
  background-position: top 5rem left;
  background-size: ccover;
  background-repeat: no-repeat;
}

.bg-section-accomodation {
  background-image: url("../../../images/home.svg");
  background-position: top 5rem right;
  background-size: ccover;
  background-repeat: no-repeat;
}
