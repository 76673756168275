.cursor-pointer {
    cursor: pointer;
}
.outline-none {
    outline: none;
}

.object-cover {
    object-fit: cover;
}

.text-base {
    font-size: 16px;
}

.text-blue-500 {
    color: $blue;
}
.text-primary-500 {
    color: $primary;
}
.text-primary-400 {
    color: #84adec;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.max-w-lg {
    max-width: 32rem;
}
.max-w-xl {
    max-width: 36rem;
}
.max-w-lg {
    max-width: 32rem;
}
.max-w-xl {
    max-width: 36rem;
}
.max-w-2xl {
    max-width: 42rem;
}
.max-w-3xl {
    max-width: 48rem;
}
.max-w-4xl {
    max-width: 56rem;
}
.font-thin {
    font-weight: 100;
}
.font-extralight {
    font-weight: 200;
}
.font-light {
    font-weight: 300;
}
.font-normal {
    font-weight: 400;
}
.font-medium {
    font-weight: 500;
}
.font-semibold {
    font-weight: 600;
}
.font-bold {
    font-weight: 700;
}
.font-extrabold {
    font-weight: 800;
}
.font-black {
    font-weight: 900;
}

.text-base {
    font-size: 16px;
}

.text-md {
    font-size: 18px;
}

.text-lg {
    font-size: 24px;
}

.text-xl {
    font-size: 30px;
}

.md-text-base {
    @include media-breakpoint-down("md") {
        font-size: 16px;
    }
}

.sm-w-full {
    @include media-breakpoint-down("md") {
        width: 100% !important;
    }
}

.lg-w-full {
    @include media-breakpoint-up("lg") {
        width: 100% !important;
    }
}

.md-text-md {
    @include media-breakpoint-down("md") {
        font-size: 18px;
    }
}

.error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #ff5b5b;
}

.underline {
    text-decoration: underline;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-gray {
    color: #b2b2b2;
}

.w-max {
    max-width: max-content;
}

.w-1-2 {
    width: 50%;
}
.w-1-3 {
    width: 33.333333%;
}
.w-2-3 {
    width: 66.666667%;
}
.w-1-4 {
    width: 25%;
}
.w-2-4 {
    width: 50%;
}
.w-3-4 {
    width: 75%;
}
.w-1-5 {
    width: 20%;
}
.w-2-5 {
    width: 40%;
}
.w-3-5 {
    width: 60%;
}
.w-4-5 {
    width: 80%;
}
.w-1-6 {
    width: 16.666667%;
}
.w-2-6 {
    width: 33.333333%;
}
.w-3-6 {
    width: 50%;
}
.w-4-6 {
    width: 66.666667%;
}
.w-5-6 {
    width: 83.333333%;
}
.w-1-12 {
    width: 8.333333%;
}
.w-2-12 {
    width: 16.666667%;
}
.w-3-12 {
    width: 25%;
}
.w-4-12 {
    width: 33.333333%;
}
.w-5-12 {
    width: 41.666667%;
}
.w-6-12 {
    width: 50%;
}
.w-7-12 {
    width: 58.333333%;
}
.w-8-12 {
    width: 66.666667%;
}
.w-9-12 {
    width: 75%;
}
.w-10-12 {
    width: 83.333333%;
}
.w-11-12 {
    width: 91.666667%;
}
.w-full {
    width: 100%;
}
.w-screen {
    width: 100vw;
}

.text-error {
    color: #ff5b5b;
    box-shadow: none;
    font-size: inherit;
    text-shadow: none;
    line-height: normal;
}

.react-select__single-value {
    color: black !important;
}

.leading-none {
    line-height: 1;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.rounded-full {
    border-radius: 9999px;
    width: 240px;
    height: 240px;
    object-fit: cover;
    object-position: center;
}

.icon-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.img-detail {
    &:hover {
        opacity: 0.5;
    }
}
.flex-none {
    flex: none;
}

.right-0 {
    right: 0;
}

.absolute {
    position: absolute;
}

.form-check-label {
    font-weight: 400 !important;
}
.relative {
    position: relative;
}
.icon-location {
    width: auto;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
}

.bg-primary-500 {
    background-color: #5cb8b2 !important;
    color: white !important;
}

.bg-blue-500 {
    background-color: #84adec;
}

.error-boundary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: $auth-bg-pattern-img;
    background-size: cover;
    background-position: center;
    &__text {
        font-size: 16px;
        font-weight: bold;
    }
}

.modal-entitie {
    .MuiDialog-paper {
        overflow-x: hidden;
    }
}

.swal2-container {
    z-index: 99999 !important;
}

.dropdown-list-users-toggle {
    .dropdown-toggle::after {
        border: 0 !important;
        button:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }
    &__menu-options {
        span {
            cursor: pointer;
            &:hover {
                background-color: #f4f6fb;
            }
        }
    }
}

.h-25vh {
    height: 25vh !important;
}

.border-b-black {
    border-bottom-width: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.border-dashed-orange {
    border: 1px dashed #ff671f;
    border-radius: 5px;
}

.border-dashed-primary {
    border: 1px dashed #cfe2f7;
    border-radius: 5px;
}
.no-print {
    @media print {
        display: none;
    }
}
