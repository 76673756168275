//
// buttons.scss
//

.btn {
    .mdi {
        &:before {
            line-height: initial;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

.btn-controlled {
    width: 100%;
    min-width: 290px;
    margin-top: 1em;
    @include media-breakpoint-up('md'){
        max-width: 398px;
        min-width: 398px;
    }
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}
